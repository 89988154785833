.stu_home{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top:0;
    left: 0;
    background-color: white;
    z-index: 999999;
}
.stu_gif{
width: 80%;
/* height: 350px; */
display: block;
margin: auto;
}