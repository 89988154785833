@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
.fn_14{
  font-size: 14px;

}
.fn_12{
  font-size: 12px;
}
.ww_60{
  width: 380px;
}

.fn_18{
  font-size: 18px;
}
.re_p{
  margin-top: -12px !important;
  color: #006BC2;
}
.dis{
  cursor: no-drop !important;
}
.flex_box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.short_p{
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.two_p{
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.big_p{
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.btn{
  padding: 8px 15px;
  border: none;
  border-radius: 0;
}
.cur{
  cursor: pointer;
}
.section_page{
  width: 100%;
  min-height: 100vh;
  padding: 75px 0;
}
.text-col{
  color: #2c3e50;
}
.text-custom{
  color: #006BC2;
}
.title_font{
  font-size: 45px;
}

.title_font_sm{
  font-size: 35px;
  
}
.fa_icon{
  font-size: 20px;
}
.border_card{
  border: 1px solid #2c3e50;
  border-radius: 10px;
  
}
.w-change{
  width: 80%;
}

@media (max-width: 768px) {
  .title_font{
    font-size: 35px;
  }
  .title_font_sm{
    font-size: 28px;
  }
}


.error {
  width: 100%;
  display: grid;
  place-items: center;
  background-color: ghostwhite;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.error h1 {
  font-size: 60px;
  font-weight: 700;
  text-shadow: 0px 0px 5px rgb(226, 131, 147);
}

.d_flex{
  display: flex;
  
}
.dd_flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}
.dd_flex_cen{
  display: flex;
  justify-content: center;
  align-items: center;

}


.textm{
  margin-top: -15px;
  margin-left: 5px;
  color: #006BC2;
}
.textm:hover{
  color: #006BC2;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(199, 194, 194);
}



.form-control {
  border:1px solid #006BC2 !important;
  
}
.form-control:focus{
  
box-shadow: none;
  
}

.filterform{
  height: 35px !important;
font-size: 15px;
}




.pagination {
  display: flex;
  justify-content: end;
  width: 100%;
  /* padding-top: 50px; */
  color: #000000;
  /* margin: auto; */
}
.pagination a {
  padding: 5px 8px;
  margin: 5px;
  border: 2px solid #006BC2;
  color: black !important;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px !important;
}
.pagination a:hover {
  color: #fff !important;
  text-decoration: none;
  background-color: #006BC2;
}
.activebutton a {
  background-color: #006BC2;
  color: #fff !important;
}



.pageloading{
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top:0;
  left: 0;
  z-index: 9999;
}

@-webkit-keyframes ldio-xz472s6kr1-1 {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg) }
   50% { -webkit-transform: rotate(-45deg); transform: rotate(-45deg) }
  100% { -webkit-transform: rotate(0deg); transform: rotate(0deg) }
}

@keyframes ldio-xz472s6kr1-1 {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg) }
   50% { -webkit-transform: rotate(-45deg); transform: rotate(-45deg) }
  100% { -webkit-transform: rotate(0deg); transform: rotate(0deg) }
}
@-webkit-keyframes ldio-xz472s6kr1-2 {
    0% { -webkit-transform: rotate(180deg); transform: rotate(180deg) }
   50% { -webkit-transform: rotate(225deg); transform: rotate(225deg) }
  100% { -webkit-transform: rotate(180deg); transform: rotate(180deg) }
}
@keyframes ldio-xz472s6kr1-2 {
    0% { -webkit-transform: rotate(180deg); transform: rotate(180deg) }
   50% { -webkit-transform: rotate(225deg); transform: rotate(225deg) }
  100% { -webkit-transform: rotate(180deg); transform: rotate(180deg) }
}
.ldio-xz472s6kr1 > div:nth-child(2) {
  -webkit-transform: translate(-15px,0);
          transform: translate(-15px,0);
}
.ldio-xz472s6kr1 > div:nth-child(2) div {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 120px;
  height: 60px;
  border-radius: 120px 120px 0 0;
  background: #ffffff;
  -webkit-animation: ldio-xz472s6kr1-1 1s linear infinite;
          animation: ldio-xz472s6kr1-1 1s linear infinite;
  -webkit-transform-origin: 60px 60px;
          transform-origin: 60px 60px
}
.ldio-xz472s6kr1 > div:nth-child(2) div:nth-child(2) {
  -webkit-animation: ldio-xz472s6kr1-2 1s linear infinite;
          animation: ldio-xz472s6kr1-2 1s linear infinite
}
.ldio-xz472s6kr1 > div:nth-child(2) div:nth-child(3) {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-animation: none;
          animation: none;
}@-webkit-keyframes ldio-xz472s6kr1-3 {
    0% { -webkit-transform: translate(190px,0); transform: translate(190px,0); opacity: 0 }
   20% { opacity: 1 }
  100% { -webkit-transform: translate(70px,0); transform: translate(70px,0); opacity: 1 }
}@keyframes ldio-xz472s6kr1-3 {
    0% { -webkit-transform: translate(190px,0); transform: translate(190px,0); opacity: 0 }
   20% { opacity: 1 }
  100% { -webkit-transform: translate(70px,0); transform: translate(70px,0); opacity: 1 }
}
.ldio-xz472s6kr1 > div:nth-child(1) {
  display: block;
}
.ldio-xz472s6kr1 > div:nth-child(1) div {
  position: absolute;
  top: 92px;
  left: -8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #006BC2;
  -webkit-animation: ldio-xz472s6kr1-3 1s linear infinite;
          animation: ldio-xz472s6kr1-3 1s linear infinite
}
.ldio-xz472s6kr1 > div:nth-child(1) div:nth-child(1) { -webkit-animation-delay: -0.67s; animation-delay: -0.67s }
.ldio-xz472s6kr1 > div:nth-child(1) div:nth-child(2) { -webkit-animation-delay: -0.33s; animation-delay: -0.33s }
.ldio-xz472s6kr1 > div:nth-child(1) div:nth-child(3) { -webkit-animation-delay: 0s; animation-delay: 0s }
.loadingio-spinner-bean-eater-ghxsb80unx {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-xz472s6kr1 {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateZ(0) scale(1);
          transform: translateZ(0) scale(1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0; /* see note above */
}
.ldio-xz472s6kr1 div { box-sizing: content-box; }
/* generated by https://loading.io/ */



.img_flex{
  display: grid;
  
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;
  gap: 10px;
}
.img_flex_item{
  height: 200px;
  width: 100%;
  
  box-shadow: 0px 2px 4px grey;
  /* cursor: pointer; */
  border-radius: 5px;
}
.img_flex_item img{
  width: 100%;
  height: 100%;
  border-radius: 5px;

}


.gallery_flex{
  display: grid;
  
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  gap: 20px;
}
.gallery_flex_item{
  height: 300px;
  width: 100%;
  
  box-shadow: 0px 2px 4px grey;
  
  border-radius: 5px;
  padding: 10px;
  position: relative;
}
.youtube_flex_item{
  
  width: 100%;
  min-height: 310px;
  box-shadow: 0px 2px 4px grey;
  
  border-radius: 5px;
  padding: 10px;
  position: relative;
}
.youtube_flex_item img{
  width: 100%;
  height: 200px;
  display: block;
  margin: auto;
  object-fit: cover;
}
.gallery_flex_item img{
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: block;
  margin: auto;
  object-fit: cover;

}
.delete_btn{
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: #006BC2;
  cursor: pointer;
  color:white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gal_title{
  position: absolute;
  bottom: 10px;
  left: 10px;
  right:10px;
  background-color: #2c3e50;
  padding:10px 5px;
  color: white;

}


.model_box{
  width: 100%;
  height: 100vh;
  position: fixed;
  top:0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(8, 1, 20, 0.5);
  z-index: 99999;
}
.inner_model{
  width:500px;
  background-color: white;
  height: auto;
  padding: 15px;
  position: relative;
}


.cross{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #006BC2;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: -10px;
  top:-10px;
}

@media (max-width:650px) {
  .inner_model {
      width: 90%;
  }
  
}


#__react-alert__ div div div {
  width: auto !important;
}

.scroll_bar , .scroll_bar .scroll_bar_content{
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
}

.inner_model_sobig{
  width:700px;
  background-color: white;
  height: auto;
  padding: 15px;
  position: relative;
}

@media (max-width:750px) {
  .inner_model_sobig {
    width: 90%;
}
}

.tab_side_bar{
  position: relative;
}

.tab_side_bar_left_button{
  position: absolute;
  left: 0;
  top:50%;
  z-index: 9;
}

.tab_side_bar_right_button{
  position: absolute;
  right: 0;
  top:50%;
  z-index: 9;

}
.student_nav{
  width: 100%;
  height: 60px;
 background-color: #0082e6;

  box-shadow: 0px 1px 5px grey;
  position:fixed;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.student_navbar{
  width: 95%;
  height: 100%;
  
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.student_flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo_nav:hover{
  background-color: none !important;
}



.stu_logoimg{
  height: 50px !important;
    object-fit: contain;
}


.resp_dis{
  display: none !important;
}


.modbox{
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.smbox{
  min-width:300px ;
  height: auto;
  background-color: white;
  padding: 15px;
  position: relative;
}

.btn_close{
  width: 25px;
  height: 25px;
  background-color: #0082e6;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:-10px;
  right: -10px;
  border-radius: 50%;
  color:white;
  cursor: pointer;
}






.whole__page{
  
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.left_side_navbar{
  position: fixed;
  top: 0;
  left: 0;
   width: 18%;
   height: 100vh;  
   overflow-y: auto;  
   background: white;
   padding-bottom: 50px;
 

}

.side_navbar_inner  a{
   
  display: block;
  color:black;

  padding: 10px ;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  width: 80%;
  
  margin: 5px;
  
}
.side_navbar_inner  a:hover{
  background-color: #0082e6;
  color:white
}
.side_navbar_inner  .active{
  background-color: #0082e6;
  color:white
}
.side_navbar_inner a:hover{
  text-decoration: none;
}

.side_navbar_inner{
    
  margin-top: 100px;
  
}

.main_div{
  position: absolute;
  top:0;
  right: 0;
  width: 82%;
  min-height: 100vh;
  /* font-size: 45px; */
  /* background-color: aquamarine; */
  background-color: ghostwhite;
  
  /* padding: 15px; */
  padding:  100px 0px 50px 0px;
}
.main_inner_div{
  width: 95%;
  display: block;
  margin: auto;
}


@media (max-width:776px) {
  .main_div{
    position: relative;
  }
  .resp_dis{
    display: block !important;
  }
  .left_side_navbar{
    display: none;
}

.main_div{
  width: 100%;
}

}
.welcome_dash{
    width: 100%;
    height: 200px;
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    color: #fff;
    border-radius: 10px;
    display: flex;
   justify-content: center;
    flex-direction: column;
    position: relative;
}
.left_stu_dash{
    position: absolute;
    left: 0;
    z-index: 2;
    

}
.right_stu_dash{
    position: absolute;
    right: 0;
    z-index: 1;
}
.right_stu_dash img{
    width: 200px;
    height: 200px;
    object-fit: contain;
    padding: 15px;
}

.card{
    border: none;
    border-radius: 0;
    box-shadow: 0px 2px 4px grey;
}
.card_img{
    width: 55px;
    height: 55px;

}
.cardflex{
    display: flex;
    
    }
    

    .badge{
        padding: 5px;
        }
        .badge-danger{
            background-color: rgba(243, 140, 145,0.4);
            color: red;
        }
        .badge-success{
            background-color: rgba(146, 248, 135, 0.4);
            color:green;
        }
        .badge-warning{
            background-color: rgba(250, 174, 33, 0.2);
            color: orange;
        }
        table{
            table-layout:fixed;
          }






          .listmy{
        
            height: 400px !important;
            overflow-y: auto;
        }


         
        
        table {
            width: 100%;
            border-collapse: collapse; /* Optional: merge table borders*/
        }
        
        thead {
            position: -webkit-sticky;
            position: sticky;
            top: 0px;
            background-color: #ffffff; /* Optional: add a background color to the thead */
        
            box-shadow: 0px 2px 2px grey;
            
        }
        
        th, td {
            padding: 8px;
            border: 1px solid #ddd; 
        }

        
          .sm_w{
            width: 150px;
          }
          .w_20{
            width: 200px;
          }
          .w_60{
            width: 270px;
            
          }
          .sm_btn{
            padding: 7px 15px;
            background-color: #006BC2;
            color: #fff;
            font-size: 14px;
            font-weight:400;
            border: none;
            cursor: pointer;

          }
          .sm_btn:hover{
            color: #fff;
            text-decoration: none;
          }
          .sm_btn:focus{
            outline: none;
          }
          .td_img{
            width: 45px;
            height: 45px;
            border-radius: 50%;
            object-fit: contain;
            box-shadow: 0px 2px 4px grey;
          }

          .short_p{
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
.listsize {
    width: 250px;
  }
  .nav-wid {
    background-color: #0082e6 !important;
    
  }
  .nav-wid a{
    color:white;
    font-size: 16px !important;
  }
  .nav-wid a:hover{
    color:white;
    text-decoration: none;
  }
  .MuiMenu-list {
    width: 250px !important;
    /* height: 250px !important; */
    overflow: auto;
  }
  /* .MuiMenu-paper {
    top: 50px !important;
  } */
  
  .MuiMenuItem-root {
    margin-bottom: 3px !important;
    /* border-bottom: 1px solid rgba(42, 159, 34, 0.5) !important; */
    /* background-color: rgb(14, 71, 52) !important; */
   

  }
  .MuiMenuItem-root a{
    display: flex;
    align-items: center;
    /* color:#000000; */
  }
  /* .MuiMenuItem-root a:hover{
    color: #000000;
    text-decoration: none;
    background-color: #026aba;
  }
.MuiButtonBase-root:hover a{
  
  color: white !important;
}
  .MuiList-root a:hover{
    background-color: #026aba;
    color: white !important;
    
  } */


  .MuiButtonBase-root:hover {
    background-color: #026aba !important;
    color: white !important;
    
  }
  .MuiList-root li a{
    color: black;
  }
  .MuiList-root li:hover a{
    color: white !important;
  }
  .MuiList-root li a:hover{
    text-decoration: none;
  }
  .MuiList-root li:hover{
    /* background-color: #026aba; */
    color: white !important;
    
  }
.stu_home{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top:0;
    left: 0;
    background-color: white;
    z-index: 999999;
}
.stu_gif{
width: 80%;
/* height: 350px; */
display: block;
margin: auto;
}

.form_h{
    height: 40px;
}


.ck-editor{
    border: 1px solid #006BC2 !important;
  }
  .ck_editor__top{
    border: 1px solid #006BC2 !important;
  }
.ck-content{
    min-height: 150px !important;
    border-top: 1px solid #006BC2 !important;
}

.ck-content:focus{
 
    box-shadow: none !important;
    border: none !important;
    border-top: 1px solid #006BC2 !important;
}

.ck-powered-by{
    display: none !important;
}
.delete_box{
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #006BC2;
    color:white;
}
.big_td_img{
    width: 80px;
    height: 80px;
    display: block;
    margin: auto;
}

.mod_img{
    width: 100%;
    height: 450px;
}
.dropdown-container{
    border:1px solid #006BC2 !important;
}

.dropdown-container:focus-within{
    border:1px solid #006BC2 !important;
    box-shadow: none !important;
      
    }

    .w_35{
        width: 250px !important;
    }
.mt5{
    margin-top: -17px;
}




.auth {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ghostwhite;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    overflow: hidden;
  }
  .auth::before {
    position: absolute;
    content: '';
    top: 0px;
    left: 0;
  
    height: 100%;
    width: 100%;
    z-index: -1;
    background-image: url('https://res.cloudinary.com/du9emrtpi/image/upload/v1672315247/undraw_reading_book_re_kqpk_udp5dc.svg');
  
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 25%;
    -webkit-animation: updown 6s linear infinite alternate;
            animation: updown 6s linear infinite alternate;
  }
  @-webkit-keyframes updown {
    0%   {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
    50%  {-webkit-transform: translateY(0%);transform: translateY(0%);}
    
    100% {-webkit-transform: translateY(5%);transform: translateY(5%);}
  }
  @keyframes updown {
    0%   {
      -webkit-transform: translateY(-5%);
              transform: translateY(-5%);
    }
    50%  {-webkit-transform: translateY(0%);transform: translateY(0%);}
    
    100% {-webkit-transform: translateY(5%);transform: translateY(5%);}
  }
  
  .auth::after {
    position: absolute;
    content: '';
    top: 0px;
    right: 0;
  
    height: 100%;
    width: 100%;
    z-index: -1;
    background-image: url('https://res.cloudinary.com/du9emrtpi/image/upload/v1672315247/undraw_road_to_knowledge_m8s0_ktjrik.svg');
  
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 25%;
    -webkit-animation: updown 5s linear infinite alternate;
            animation: updown 5s linear infinite alternate;
  }
  
  
  
  @media (max-width: 600px) {
    .auth::before {
      background-size: 50%;
    }
  }

  .logoimg{
    width: 150px;
    height: 50px;
    object-fit: contain;
    display: block;
    margin: auto;
    margin-bottom: 15px;
  }
.accordion_whole {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* row-gap: 20px; */
}

.accordion_whole .accordion__item {
    width: 100%;
   
    /* background-color: grey; */
   padding: 5px;
   /* padding-left: 25px; */
box-shadow: 0px 1px 4px grey;
    color: #000000;
    overflow: hidden;
   
    margin: 15px 0;
}

.accordion_whole .accordion__header {
  
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}


.accordion_whole .accordion__header h4 {
    transition: 0.2s ease-in-out;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 10px;
    color:#000000 ;

}

.accordion__content h4{
    font-size: 16px;
    
}
.accordion__content h4 span{
 color: #5d5d5d;  
 margin-right: 10px; 
}
.accordion_whole .accordion__header i {
    transition: 0.2s ease-in-out;
    -webkit-transform-origin: center;
            transform-origin: center;
    margin-bottom: 10px;
}

.accordion_whole .accordion__header:hover h4 {
    color: #0082e6;
}

.accordion_whole .accordion__header:hover i {
    color: #0082e6;
}

.accordion_whole .accordion__content {
    padding: 10px 0;
    border-top: 1px solid #000;
}


.sml_btn{
    padding: 7px 15px;
    background-color: grey;
    color: #fff;
    font-size: 14px;
    font-weight:400;
    border: none;
    cursor: pointer;

  }
  .sml_btn:hover{
    color: #fff;
    text-decoration: none;
  }
  .sml_btn:focus{
    outline: none;
  }
.active_btn{
    background-color: #006BC2 !important;
}


.purches_img{
    width: 100%;
    height:300px;
    object-fit: cover;
    display: block;
    margin: auto;
    border-radius: 10px;

}

.purches_img_book{
    width: 100%;
    height:300px !important;
   object-fit: contain !important;
    display: block;
    margin: auto;
    border-radius: 10px;

}

.title_a{
    color:black;
   
}
.title_a:hover{
    text-decoration: none;
    color:black;
}
.title_a h4{
    word-wrap: break-word !important;
    font-size: 20px;
}
.user_search{
    margin-bottom: 10px;
}

.user_search input{
    outline: none !important;
    width: 100%;
    max-width: 500px !important;
    padding: 10px 5px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid #006BC2;
}
