@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
.fn_14{
  font-size: 14px;

}
.fn_12{
  font-size: 12px;
}
.ww_60{
  width: 380px;
}

.fn_18{
  font-size: 18px;
}
.re_p{
  margin-top: -12px !important;
  color: #006BC2;
}
.dis{
  cursor: no-drop !important;
}
.flex_box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.short_p{
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.two_p{
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.big_p{
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.btn{
  padding: 8px 15px;
  border: none;
  border-radius: 0;
}
.cur{
  cursor: pointer;
}
.section_page{
  width: 100%;
  min-height: 100vh;
  padding: 75px 0;
}
.text-col{
  color: #2c3e50;
}
.text-custom{
  color: #006BC2;
}
.title_font{
  font-size: 45px;
}

.title_font_sm{
  font-size: 35px;
  
}
.fa_icon{
  font-size: 20px;
}
.border_card{
  border: 1px solid #2c3e50;
  border-radius: 10px;
  
}
.w-change{
  width: 80%;
}

@media (max-width: 768px) {
  .title_font{
    font-size: 35px;
  }
  .title_font_sm{
    font-size: 28px;
  }
}


.error {
  width: 100%;
  display: grid;
  place-items: center;
  background-color: ghostwhite;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.error h1 {
  font-size: 60px;
  font-weight: 700;
  text-shadow: 0px 0px 5px rgb(226, 131, 147);
}

.d_flex{
  display: flex;
  
}
.dd_flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}
.dd_flex_cen{
  display: flex;
  justify-content: center;
  align-items: center;

}


.textm{
  margin-top: -15px;
  margin-left: 5px;
  color: #006BC2;
}
.textm:hover{
  color: #006BC2;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(199, 194, 194);
}



.form-control {
  border:1px solid #006BC2 !important;
  
}
.form-control:focus{
  
box-shadow: none;
  
}

.filterform{
  height: 35px !important;
font-size: 15px;
}




.pagination {
  display: flex;
  justify-content: end;
  width: 100%;
  /* padding-top: 50px; */
  color: #000000;
  /* margin: auto; */
}
.pagination a {
  padding: 5px 8px;
  margin: 5px;
  border: 2px solid #006BC2;
  color: black !important;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px !important;
}
.pagination a:hover {
  color: #fff !important;
  text-decoration: none;
  background-color: #006BC2;
}
.activebutton a {
  background-color: #006BC2;
  color: #fff !important;
}



.pageloading{
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top:0;
  left: 0;
  z-index: 9999;
}

@keyframes ldio-xz472s6kr1-1 {
    0% { transform: rotate(0deg) }
   50% { transform: rotate(-45deg) }
  100% { transform: rotate(0deg) }
}
@keyframes ldio-xz472s6kr1-2 {
    0% { transform: rotate(180deg) }
   50% { transform: rotate(225deg) }
  100% { transform: rotate(180deg) }
}
.ldio-xz472s6kr1 > div:nth-child(2) {
  transform: translate(-15px,0);
}
.ldio-xz472s6kr1 > div:nth-child(2) div {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 120px;
  height: 60px;
  border-radius: 120px 120px 0 0;
  background: #ffffff;
  animation: ldio-xz472s6kr1-1 1s linear infinite;
  transform-origin: 60px 60px
}
.ldio-xz472s6kr1 > div:nth-child(2) div:nth-child(2) {
  animation: ldio-xz472s6kr1-2 1s linear infinite
}
.ldio-xz472s6kr1 > div:nth-child(2) div:nth-child(3) {
  transform: rotate(-90deg);
  animation: none;
}@keyframes ldio-xz472s6kr1-3 {
    0% { transform: translate(190px,0); opacity: 0 }
   20% { opacity: 1 }
  100% { transform: translate(70px,0); opacity: 1 }
}
.ldio-xz472s6kr1 > div:nth-child(1) {
  display: block;
}
.ldio-xz472s6kr1 > div:nth-child(1) div {
  position: absolute;
  top: 92px;
  left: -8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #006BC2;
  animation: ldio-xz472s6kr1-3 1s linear infinite
}
.ldio-xz472s6kr1 > div:nth-child(1) div:nth-child(1) { animation-delay: -0.67s }
.ldio-xz472s6kr1 > div:nth-child(1) div:nth-child(2) { animation-delay: -0.33s }
.ldio-xz472s6kr1 > div:nth-child(1) div:nth-child(3) { animation-delay: 0s }
.loadingio-spinner-bean-eater-ghxsb80unx {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-xz472s6kr1 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-xz472s6kr1 div { box-sizing: content-box; }
/* generated by https://loading.io/ */



.img_flex{
  display: grid;
  
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}
.img_flex_item{
  height: 200px;
  width: 100%;
  
  box-shadow: 0px 2px 4px grey;
  /* cursor: pointer; */
  border-radius: 5px;
}
.img_flex_item img{
  width: 100%;
  height: 100%;
  border-radius: 5px;

}


.gallery_flex{
  display: grid;
  
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}
.gallery_flex_item{
  height: 300px;
  width: 100%;
  
  box-shadow: 0px 2px 4px grey;
  
  border-radius: 5px;
  padding: 10px;
  position: relative;
}
.youtube_flex_item{
  
  width: 100%;
  min-height: 310px;
  box-shadow: 0px 2px 4px grey;
  
  border-radius: 5px;
  padding: 10px;
  position: relative;
}
.youtube_flex_item img{
  width: 100%;
  height: 200px;
  display: block;
  margin: auto;
  object-fit: cover;
}
.gallery_flex_item img{
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: block;
  margin: auto;
  object-fit: cover;

}
.delete_btn{
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: #006BC2;
  cursor: pointer;
  color:white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gal_title{
  position: absolute;
  bottom: 10px;
  left: 10px;
  right:10px;
  background-color: #2c3e50;
  padding:10px 5px;
  color: white;

}


.model_box{
  width: 100%;
  height: 100vh;
  position: fixed;
  top:0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(8, 1, 20, 0.5);
  z-index: 99999;
}
.inner_model{
  width:500px;
  background-color: white;
  height: auto;
  padding: 15px;
  position: relative;
}


.cross{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #006BC2;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: -10px;
  top:-10px;
}

@media (max-width:650px) {
  .inner_model {
      width: 90%;
  }
  
}


#__react-alert__ div div div {
  width: auto !important;
}

.scroll_bar , .scroll_bar .scroll_bar_content{
  transform: rotateX(180deg);
}

.inner_model_sobig{
  width:700px;
  background-color: white;
  height: auto;
  padding: 15px;
  position: relative;
}

@media (max-width:750px) {
  .inner_model_sobig {
    width: 90%;
}
}

.tab_side_bar{
  position: relative;
}

.tab_side_bar_left_button{
  position: absolute;
  left: 0;
  top:50%;
  z-index: 9;
}

.tab_side_bar_right_button{
  position: absolute;
  right: 0;
  top:50%;
  z-index: 9;

}