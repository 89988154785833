.student_nav{
  width: 100%;
  height: 60px;
 background-color: #0082e6;

  box-shadow: 0px 1px 5px grey;
  position:fixed;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.student_navbar{
  width: 95%;
  height: 100%;
  
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.student_flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo_nav:hover{
  background-color: none !important;
}



.stu_logoimg{
  height: 50px !important;
    object-fit: contain;
}


.resp_dis{
  display: none !important;
}


.modbox{
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.smbox{
  min-width:300px ;
  height: auto;
  background-color: white;
  padding: 15px;
  position: relative;
}

.btn_close{
  width: 25px;
  height: 25px;
  background-color: #0082e6;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:-10px;
  right: -10px;
  border-radius: 50%;
  color:white;
  cursor: pointer;
}






.whole__page{
  
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.left_side_navbar{
  position: fixed;
  top: 0;
  left: 0;
   width: 18%;
   height: 100vh;  
   overflow-y: auto;  
   background: white;
   padding-bottom: 50px;
 

}

.side_navbar_inner  a{
   
  display: block;
  color:black;

  padding: 10px ;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  width: 80%;
  
  margin: 5px;
  
}
.side_navbar_inner  a:hover{
  background-color: #0082e6;
  color:white
}
.side_navbar_inner  .active{
  background-color: #0082e6;
  color:white
}
.side_navbar_inner a:hover{
  text-decoration: none;
}

.side_navbar_inner{
    
  margin-top: 100px;
  
}

.main_div{
  position: absolute;
  top:0;
  right: 0;
  width: 82%;
  min-height: 100vh;
  /* font-size: 45px; */
  /* background-color: aquamarine; */
  background-color: ghostwhite;
  
  /* padding: 15px; */
  padding:  100px 0px 50px 0px;
}
.main_inner_div{
  width: 95%;
  display: block;
  margin: auto;
}


@media (max-width:776px) {
  .main_div{
    position: relative;
  }
  .resp_dis{
    display: block !important;
  }
  .left_side_navbar{
    display: none;
}

.main_div{
  width: 100%;
}

}