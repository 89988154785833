.welcome_dash{
    width: 100%;
    height: 200px;
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    color: #fff;
    border-radius: 10px;
    display: flex;
   justify-content: center;
    flex-direction: column;
    position: relative;
}
.left_stu_dash{
    position: absolute;
    left: 0;
    z-index: 2;
    

}
.right_stu_dash{
    position: absolute;
    right: 0;
    z-index: 1;
}
.right_stu_dash img{
    width: 200px;
    height: 200px;
    object-fit: contain;
    padding: 15px;
}

.card{
    border: none;
    border-radius: 0;
    box-shadow: 0px 2px 4px grey;
}
.card_img{
    width: 55px;
    height: 55px;

}
.cardflex{
    display: flex;
    
    }
    

    .badge{
        padding: 5px;
        }
        .badge-danger{
            background-color: rgba(243, 140, 145,0.4);
            color: red;
        }
        .badge-success{
            background-color: rgba(146, 248, 135, 0.4);
            color:green;
        }
        .badge-warning{
            background-color: rgba(250, 174, 33, 0.2);
            color: orange;
        }
        table{
            table-layout:fixed;
          }






          .listmy{
        
            height: 400px !important;
            overflow-y: auto;
        }


         
        
        table {
            width: 100%;
            border-collapse: collapse; /* Optional: merge table borders*/
        }
        
        thead {
            position: sticky;
            top: 0px;
            background-color: #ffffff; /* Optional: add a background color to the thead */
        
            box-shadow: 0px 2px 2px grey;
            
        }
        
        th, td {
            padding: 8px;
            border: 1px solid #ddd; 
        }

        
          .sm_w{
            width: 150px;
          }
          .w_20{
            width: 200px;
          }
          .w_60{
            width: 270px;
            
          }
          .sm_btn{
            padding: 7px 15px;
            background-color: #006BC2;
            color: #fff;
            font-size: 14px;
            font-weight:400;
            border: none;
            cursor: pointer;

          }
          .sm_btn:hover{
            color: #fff;
            text-decoration: none;
          }
          .sm_btn:focus{
            outline: none;
          }
          .td_img{
            width: 45px;
            height: 45px;
            border-radius: 50%;
            object-fit: contain;
            box-shadow: 0px 2px 4px grey;
          }

          .short_p{
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }