.accordion_whole {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* row-gap: 20px; */
}

.accordion_whole .accordion__item {
    width: 100%;
   
    /* background-color: grey; */
   padding: 5px;
   /* padding-left: 25px; */
box-shadow: 0px 1px 4px grey;
    color: #000000;
    overflow: hidden;
   
    margin: 15px 0;
}

.accordion_whole .accordion__header {
  
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}


.accordion_whole .accordion__header h4 {
    transition: 0.2s ease-in-out;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 10px;
    color:#000000 ;

}

.accordion__content h4{
    font-size: 16px;
    
}
.accordion__content h4 span{
 color: #5d5d5d;  
 margin-right: 10px; 
}
.accordion_whole .accordion__header i {
    transition: 0.2s ease-in-out;
    transform-origin: center;
    margin-bottom: 10px;
}

.accordion_whole .accordion__header:hover h4 {
    color: #0082e6;
}

.accordion_whole .accordion__header:hover i {
    color: #0082e6;
}

.accordion_whole .accordion__content {
    padding: 10px 0;
    border-top: 1px solid #000;
}

