.listsize {
    width: 250px;
  }
  .nav-wid {
    background-color: #0082e6 !important;
    
  }
  .nav-wid a{
    color:white;
    font-size: 16px !important;
  }
  .nav-wid a:hover{
    color:white;
    text-decoration: none;
  }
  .MuiMenu-list {
    width: 250px !important;
    /* height: 250px !important; */
    overflow: auto;
  }
  /* .MuiMenu-paper {
    top: 50px !important;
  } */
  
  .MuiMenuItem-root {
    margin-bottom: 3px !important;
    /* border-bottom: 1px solid rgba(42, 159, 34, 0.5) !important; */
    /* background-color: rgb(14, 71, 52) !important; */
   

  }
  .MuiMenuItem-root a{
    display: flex;
    align-items: center;
    /* color:#000000; */
  }
  /* .MuiMenuItem-root a:hover{
    color: #000000;
    text-decoration: none;
    background-color: #026aba;
  }
.MuiButtonBase-root:hover a{
  
  color: white !important;
}
  .MuiList-root a:hover{
    background-color: #026aba;
    color: white !important;
    
  } */


  .MuiButtonBase-root:hover {
    background-color: #026aba !important;
    color: white !important;
    
  }
  .MuiList-root li a{
    color: black;
  }
  .MuiList-root li:hover a{
    color: white !important;
  }
  .MuiList-root li a:hover{
    text-decoration: none;
  }
  .MuiList-root li:hover{
    /* background-color: #026aba; */
    color: white !important;
    
  }