.purches_img{
    width: 100%;
    height:300px;
    object-fit: cover;
    display: block;
    margin: auto;
    border-radius: 10px;

}

.purches_img_book{
    width: 100%;
    height:300px !important;
   object-fit: contain !important;
    display: block;
    margin: auto;
    border-radius: 10px;

}

.title_a{
    color:black;
   
}
.title_a:hover{
    text-decoration: none;
    color:black;
}
.title_a h4{
    word-wrap: break-word !important;
    font-size: 20px;
}