.user_search{
    margin-bottom: 10px;
}

.user_search input{
    outline: none !important;
    width: 100%;
    max-width: 500px !important;
    padding: 10px 5px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid #006BC2;
}