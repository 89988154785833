.sml_btn{
    padding: 7px 15px;
    background-color: grey;
    color: #fff;
    font-size: 14px;
    font-weight:400;
    border: none;
    cursor: pointer;

  }
  .sml_btn:hover{
    color: #fff;
    text-decoration: none;
  }
  .sml_btn:focus{
    outline: none;
  }
.active_btn{
    background-color: #006BC2 !important;
}