.form_h{
    height: 40px;
}


.ck-editor{
    border: 1px solid #006BC2 !important;
  }
  .ck_editor__top{
    border: 1px solid #006BC2 !important;
  }
.ck-content{
    min-height: 150px !important;
    border-top: 1px solid #006BC2 !important;
}

.ck-content:focus{
 
    box-shadow: none !important;
    border: none !important;
    border-top: 1px solid #006BC2 !important;
}

.ck-powered-by{
    display: none !important;
}
.delete_box{
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #006BC2;
    color:white;
}
.big_td_img{
    width: 80px;
    height: 80px;
    display: block;
    margin: auto;
}

.mod_img{
    width: 100%;
    height: 450px;
}
.dropdown-container{
    border:1px solid #006BC2 !important;
}

.dropdown-container:focus-within{
    border:1px solid #006BC2 !important;
    box-shadow: none !important;
      
    }

    .w_35{
        width: 250px !important;
    }