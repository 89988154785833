.auth {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ghostwhite;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    overflow: hidden;
  }
  .auth::before {
    position: absolute;
    content: '';
    top: 0px;
    left: 0;
  
    height: 100%;
    width: 100%;
    z-index: -1;
    background-image: url('https://res.cloudinary.com/du9emrtpi/image/upload/v1672315247/undraw_reading_book_re_kqpk_udp5dc.svg');
  
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 25%;
    animation: updown 6s linear infinite alternate;
  }
  @keyframes updown {
    0%   {
      transform: translateY(-5%);
    }
    50%  {transform: translateY(0%);}
    
    100% {transform: translateY(5%);}
  }
  
  .auth::after {
    position: absolute;
    content: '';
    top: 0px;
    right: 0;
  
    height: 100%;
    width: 100%;
    z-index: -1;
    background-image: url('https://res.cloudinary.com/du9emrtpi/image/upload/v1672315247/undraw_road_to_knowledge_m8s0_ktjrik.svg');
  
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 25%;
    animation: updown 5s linear infinite alternate;
  }
  
  
  
  @media (max-width: 600px) {
    .auth::before {
      background-size: 50%;
    }
  }

  .logoimg{
    width: 150px;
    height: 50px;
    object-fit: contain;
    display: block;
    margin: auto;
    margin-bottom: 15px;
  }